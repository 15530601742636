
// import Swiper bundle with all modules installed
import { bottom } from '@popperjs/core';
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';
// import wow animation
import WOW from 'wow.js'

// show Page Loading div until the page has finished loading
$(window).on('load', function () {
  $('#loading').hide();
}) 
// init Swiper:

const swiper_home = new Swiper('#home-slider', {
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  preloadImages: false,
  lazy: true,
  watchSlidesProgress: true,
  checkInView: true,
  navigation: {
    nextEl: '#swiper-button-next-home',
    prevEl: '#swiper-button-prev-home',
  },
  pagination: {
    el: '#swiper-pagination-home',
    type: 'bullets',
  },
  // Navigation arrows
  
  breakpoints: {
    767: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    
    991: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
  },
});
const swiper_hometeh = new Swiper('#hometeh-swiper', {
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  preloadImages: false,
  lazy: true,
  watchSlidesProgress: true,
  checkInView: true,
  navigation: {
    nextEl: '#swiper-button-next-hometeh',
    prevEl: '#swiper-button-prev-hometeh',
  },
  pagination: {
    el: '#swiper-pagination-hometeh',
    type: 'bullets',
  },
  breakpoints: {
    767: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    991: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
  },
});
const swiper = new Swiper('#products-swiper', {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    preloadImages: false,
    lazy: true,
    watchSlidesProgress: true,
    checkInView: true,
    navigation: {
      nextEl: '#swiper-button-next-prod',
      prevEl: '#swiper-button-prev-prod',
    },
    pagination: {
      el: '#swiper-pagination-prod',
      type: 'bullets',
    },
    breakpoints: {
      767: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      
      991: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
    },
});
const swiper_dev = new Swiper('#dev-swiper', {
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  preloadImages: false,
  lazy: true,
  watchSlidesProgress: true,
  checkInView: true,
  navigation: {
    nextEl: '#swiper-button-next-dev',
    prevEl: '#swiper-button-prev-dev',
  },
  pagination: {
    el: '#swiper-pagination-dev',
    type: 'bullets',
  },
  breakpoints: {
    767: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    
    991: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
  },
});
const swiper_tech_01 = new Swiper('#tech-processes-slider-01', {
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  preloadImages: false,
  lazy: true,
  watchSlidesProgress: true,
  checkInView: true,
  navigation: {
    nextEl: '#swiper-button-next-teh-01',
    prevEl: '#swiper-button-prev-teh-01',
  },
  pagination: {
    el: '#swiper-pagination-teh-01',
    type: 'bullets',
  },
  breakpoints: {
    767: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    991: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
  },
});
const swiper_tech_02 = new Swiper('#tech-processes-slider-02', {
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  preloadImages: false,
  lazy: true,
  watchSlidesProgress: true,
  checkInView: true,
  navigation: {
    nextEl: '#swiper-button-next-teh-02',
    prevEl: '#swiper-button-prev-teh-02',
  },
  pagination: {
    el: '#swiper-pagination-teh-02',
    type: 'bullets',
  },
  breakpoints: {
    767: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    
    991: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
  },
});
const swiper_tech_03 = new Swiper('#tech-processes-slider-03', {
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  preloadImages: false,
  lazy: true,
  watchSlidesProgress: true,
  checkInView: true,
  navigation: {
    nextEl: '#swiper-button-next-teh-03',
    prevEl: '#swiper-button-prev-teh-03',
  },
  pagination: {
    el: '#swiper-pagination-teh-03',
    type: 'bullets',
  },
  breakpoints: {
    767: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    991: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
  },
});
const swiper_ext = new Swiper('#external-processes-slider', {
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  preloadImages: false,
  lazy: true,
  watchSlidesProgress: true,
  checkInView: true,
  navigation: {
    nextEl: '#swiper-button-next-ext',
    prevEl: '#swiper-button-prev-ext',
  },
  pagination: {
    el: '#swiper-pagination-ext',
    type: 'bullets',
  },
  breakpoints: {
    767: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    
    991: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
  },
});
const swiper_tech_tools = new Swiper('#tech-tools-slider', {
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  preloadImages: false,
  lazy: true,
  watchSlidesProgress: true,
  checkInView: true,
  navigation: {
    nextEl: '#swiper-button-next-tech-tools',
    prevEl: '#swiper-button-prev-tech-tools',
  },
  pagination: {
    el: '#swiper-pagination-tech-tools',
    type: 'bullets',
  },
  breakpoints: {
    767: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    991: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
  },
});
const swiper_quality = new Swiper('#quality-slider', {
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  preloadImages: false,
  lazy: true,
  watchSlidesProgress: true,
  checkInView: true,
  navigation: {
    nextEl: '#swiper-button-next-quality',
    prevEl: '#swiper-button-prev-quality',
  },
  pagination: {
    el: '#swiper-pagination-quality',
    type: 'bullets',
  },
  breakpoints: {
    767: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    991: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
  },
});
const swiper_mockup_quality = new Swiper('#quality-mockup-slider', {
  slidesPerView: 1,
  loop: true,
  preloadImages: false,
  lazy: true,
  watchSlidesProgress: true,
  checkInView: true,
  autoplay: {
    delay: 2000,
  },
  navigation: false,
  pagination: {
    el: '#swiper-pagination-mockup',
    type: 'bullets',
  },
  breakpoints: {
    767: {
      navigation: {
        nextEl: '#swiper-button-next-mockup',
        prevEl: '#swiper-button-prev-mockup',
      },
    },
  },
});
const swiper_references = new Swiper('#references-slider', {
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  preloadImages: false,
  lazy: true,
  watchSlidesProgress: true,
  checkInView: true,
  autoplay: {
    delay: 1000,
  },
  navigation: {
    nextEl: '#swiper-button-next-references',
    prevEl: '#swiper-button-prev-references',
  },
  pagination: {
    el: '#swiper-pagination-references',
    type: 'bullets',
  },
  breakpoints: {
    575: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    
    991: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
});

$(document).ready(function() {
  // Wow animation
    var wow = new WOW(
    {
      boxClass:     'wow',      // animated element css class (default is wow)
      animateClass: 'animated', // animation css class (default is animated)
      offset:       100,          // distance to the element when triggering the animation (default is 0)
      mobile:       false,       // trigger animations on mobile devices (default is true)
      live:         true,       // act on asynchronously loaded content (default is true)
      scrollContainer: null,    // optional scroll container selector, otherwise use window,
      resetAnimation: true,     // reset animation on end (default is true)
    }
  );
  wow.init();
  
  //Tooltipster
  if ( window.location.pathname == '/' ) {
    $('#wireframe-svg-1').tooltipster({
      content: $('<div class="tooltipster-image-wrapper"><img src="images/Banner/Popup_slika_2x.webp" /></div>'),
      animation: 'fade',
      delay: 300,
      speed: 100,
      theme: 'hero-theme',
      touchDevices: true,
      trigger: 'click',
      interactive: false,
      arrow: false,
      functionBefore: function(origin, tooltip) {
        $("#wireframe-svg-1").addClass("wireframe-svg-1-hover");
      },
      functionAfter: function(origin) {
        $("#wireframe-svg-1").removeClass("wireframe-svg-1-hover");
      },
    });
  }
  

$('#Path_1213').tooltipster({
  content: $("<div class='map-tooltipster'><p>Russia</p><p>Bosch Samara Russia</p></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1153').tooltipster({
  content: $("<div class='map-tooltipster'><p>Italy</p><p>Automobili Lamborgini</p></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1235').tooltipster({
  content: $("<div class='map-tooltipster'><p>Malaysia</p><p>Bosch AS</p></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1227').tooltipster({
  content: $("<div class='map-tooltipster'><p>India</p><p>Bosch AS</p></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1214').tooltipster({
  content: $("<div class='map-tooltipster'><p>China</p><ul><li>BHSS Shanghai</li><li>Thyssendrupp Presta (Shanghai)</li></ul></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1159').tooltipster({
  content: $("<div class='map-tooltipster'><p>Slovenia</p><ul><li>Hella HSS</li><li>Siliko</li></ul></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1149').tooltipster({
  content: $("<div class='map-tooltipster'><p>Liechtenstein</p><p>Thyssenkrupp Presta</p></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1158').tooltipster({
  content: $("<div class='map-tooltipster'><p>Austria</p><p>Samsung SDI BS</p> <p>(former MagnaSteyr)</p></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1166').tooltipster({
  content: $("<div class='map-tooltipster'><p>Hungary</p><ul><li>Bosch AS</li><li>Bosch ASC Hungary</li><li>Thyssenkrupp Components Technology Hungary Kft.</li></ul></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1167').tooltipster({
  content: $("<div class='map-tooltipster'><p>Slovakia</p><p>ZF Active Safety Slovakia</p></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1157').tooltipster({
  content: $("<div class='map-tooltipster'><p>Czech Republic</p><ul><li>Parker Czech</li><li>JTEKT Automotive Czech</li></ul></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1148').tooltipster({
  content: $("<div class='map-tooltipster'><p>France</p><ul><li>Bosch AS France</li><li>JTEKT</li></ul></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1113').tooltipster({
  content: $("<div class='map-tooltipster'><p>England</p><p>Bentley Motors</p></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1145').tooltipster({
  content: $("<div class='map-tooltipster'><p>Germany</p><ul><li>Bosch Automotive Steering (fomer ZF Lenksysteme), Bosch ASC</li><li>VW</li><li>Knorr Bremse (former Tedrive)</li><li>Porsche</li><li>Audi</li><li>Rupf</li><li>Horbiger</li><li>KAG Kahlig</li><li>Stiwa</li><li>Eaton</li></ul></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1019').tooltipster({
  content: $("<div class='map-tooltipster'><p>USA</p><p>Bosch AS LLC USA</p></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1005').tooltipster({
  content: $("<div class='map-tooltipster'><p>USA</p><p>Bosch AS LLC USA</p></div>"),
  theme: 'map-theme',
  arrow: false,
});
if($('#Path_1005').hasClass('tooltipsted'))
$('#Path_1032').tooltipster({
  content: $("<div class='map-tooltipster'><p>Mexico</p><ul><li>Thyssenkrup Presta de Mexico</li><li>Bosch AS LLC, MX</li></ul></div>"),
  theme: 'map-theme',
  arrow: false,
});
$('#Path_1168').tooltipster({
  content: $("<div class='map-tooltipster'><p>Poland</p><p>ZF Automotive Systems Poland</p></div>"),
  theme: 'map-theme',
  arrow: false,
});

  $(".navbar-toggler").click(function(){
    $('.lang-switcher').toggleClass('visible');
    $("body").toggleClass("overlay-not-visible");
    $(".logo-image").toggleClass("logo-center");
  });
});

/* When the user scrolls down more than 50px in the menu, hide the logo image 
and show it if scroll top is less than 50px*/

var navbar = document.getElementsByClassName("navbar-collapse")[0];
if ( window.location.pathname == '/' ){
  navbar.onscroll = function() {
    if (navbar.scrollTop > 50){
      document.getElementById("logo-front-page").style.top = "-150px";
      document.getElementById("l-switcher").style.top = "-150px";
    } else {
      document.getElementById("logo-front-page").style.top = "0";
      document.getElementById("l-switcher").style.top = "90px";
    }
  }
} else {
  navbar.onscroll = function() {
    if (navbar.scrollTop > 50){
      document.getElementById("logo-positive").style.top = "-150px";
      document.getElementById("logo-negative").style.top = "-150px";
      document.getElementById("l-switcher").style.top = "-150px";
    } else {
      document.getElementById("logo-positive").style.top = "0";
      document.getElementById("logo-negative").style.top = "0";
      document.getElementById("l-switcher").style.top = "90px";
    }
  }
}

window.addEventListener("scroll", function(){ 
   var st = window.pageYOffset;
   if (window.pageYOffset > 100){
    $('#tt-button').fadeIn('slow');
    $('#wireframe-svg-1').tooltipster('hide');
   } else {
    $('#tt-button').fadeOut('slow');
   }
}, false);

$("#toggle-button").click(function(){
  $("#toggle-text").toggleClass("show-text");
});
$("#tech-toggle-button").click(function(){
  $("#tech-toggle-text").toggleClass("show-text");
});
$("#assembly-toggle-button").click(function(){
  $("#assembly-toggle-text").toggleClass("show-text");
});
$("#molding-toggle-button").click(function(){
  $("#molding-toggle-text").toggleClass("show-text");
});
$("#transformation-toggle-button").click(function(){
  $("#transformation-toggle-text").toggleClass("show-text");
});
$("#sup-processes-toggle-button").click(function(){
  $("#sup-processes-toggle-text").toggleClass("show-text");
});
$("#tech-processing-toggle").click(function(){
  $(this).toggleClass("change-text");
});


var hash = window.location.hash
if(hash) {
  setTimeout(
    function() 
    {
      $(hash).trigger("click");
    }, 800);
}

// Show full screen image on click in slider
var image_id;

$('#home-slider .swiper-slide').click(
function() {
  image_id = this.id;
    $('#hover-image-' + image_id).children('img').lazy({
      bind: "event"
  });
    $('#hover-image-' + image_id).fadeIn('slow');
}
);

$('.hover-image').click(
function() {
    $('.hover-image').fadeOut('slow');

  }
);

// opening all external links on new tab
// $( 'a[href^="https://"]' ).attr( 'target','_blank' )

$("#social-links").attr('id', 's-link');
$(".social-button").attr('class', 's-button');
$(".s-button").attr('target','_blank');
$(".fa-facebook-square").attr('class', 'fb-fa');
$(".fa-twitter").attr('class', 'tw-fa');
$(".fa-linkedin").attr('class', 'ln-fa');
$(".fa-whatsapp").attr('class', 'wa-fa');
