
( function( $ ) {
    let dropdowns = [];
    jQuery(document).ready(function () {
      // If mobile menu, prevent click on parent nav item from redirecting to URL
      if (isMobileMenu()) {
            let i = 0;
            jQuery(".menu-item-has-children>a").each(function (index, elem) {
            // Option 1: Use this to modify the href on the <a> to # 
            dropdowns[index] = jQuery(elem).attr('href');
            jQuery(elem).attr('href', '#');
        
        });
      }
    });
    $('.menu-item-has-children').on('click', function(){
        
        if (isMobileMenu()) {
            if($(this).children('ul.sub-menu').hasClass("show-sub-menu")){
                $(this).children('ul:first').removeClass('show-sub-menu');
                let call = window.event ? window.event.srcElement : e.target;
                jQuery(".menu-item-has-children>a").each(function (index, elem) {
                    if(elem === call)
                    window.location.replace(dropdowns[index]);
                });
            }
            else{
                removeShowClass();
                $(this).children('ul.sub-menu').addClass('show-sub-menu');
                // $(this).children('ul:first').addClass('show-sub-menu');
            }
        }
    });
    // Find all links with menu-item-has-children class
    var menu_children = document.querySelectorAll(".menu-item-has-children");
    // console.log(menu_children);
    // Remove show-sub-menu classes from this links
    function removeShowClass(){
      for (let i = 0; i < menu_children.length; i++){
        if (menu_children[i].querySelector('ul.sub-menu').classList.contains("show-sub-menu")){
            menu_children[i].querySelector('ul.sub-menu').classList.remove("show-sub-menu");
        }
      }
    }
    document.querySelector(".navbar-collapse").addEventListener("click", function (e) {
      if (e.target === this){
        removeShowClass();
      }
    });
    document.querySelector(".navbar-collapse").addEventListener("touchstart", function (e) {
      if (e.target === this){
        removeShowClass();
      }
    });

    document.getElementById("mobile-container").addEventListener("click", function (e) {
        if (e.target === this){
            removeShowClass();
        }
      });
      document.getElementById("mobile-container").addEventListener("touchstart", function (e) {
        if (e.target === this){
            removeShowClass();
        }
      });
    
    // Check if max width of the window is less then 991px
    function isMobileMenu() {
        return (window.matchMedia('(max-width: 991px)').matches);
    }
}( jQuery ) );

